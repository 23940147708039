import React, { useState, useEffect } from "react"
import { Container, Row, Col, Tab, ListGroup } from "react-bootstrap"
import Layout from "../components/Layout"
import ListMatches from "../components/ListMatches"
import Slider from "../components/SliderHome"
import styles from "../scss/pages/_budx.module.scss"
import Plx from "react-plx"
import PremierBlack from "../images/icons/PremierLeague.png"
import PremierWhite from "../images/icons/PremierLeagueWhite.png"
import LaLigaWhite from "../images/icons/LaLiga.png"
import LaLigaBlack from "../images/icons/LaLigaBlack.png"
import ArrowDown from "../images/icons/arrowDown.png"
import SEO from "../components/seo"
import AgeGate from "../components/AgeGate"
import Cookies from "js-cookie"

const BudX = ({ data }) => {
  const [linkVideo, setLinkVideo] = useState("")
  const [matches, setMatches] = useState([])
  const [buttonLaLiga, setButtonLaLiga] = useState(true)
  const [iconLaLiga, setIconLaLiga] = useState(LaLigaBlack)
  const [buttonPremierLeague, setButtonPremierLeague] = useState(false)
  const [iconPremierLeague, setIconPremierLeague] = useState(PremierWhite)
  const [listSlider, setListSlider] = useState([])
  const [age, setAge] = useState(true)

  useEffect(() => {
    let td = Cookies.get("_td")
    window.dataLayer.push({
      brand: "Budweiser",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
        window.location.search.includes("?cmp=")
          ? window.location.search.split("?cmp=")[1].split("&")[0]
          : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "Fútbol",
      pageType: "Basic page",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.budweiser.com.ar/futbol",
      url_campaign: `${
        window.location.search.includes("utm_campaign=")
          ? window.location.search.split("utm_campaign=")[1]
          : ""
      }`,
      Step_number: "",
    })
  }, [])

  useEffect(() => {
    let cookie = Cookies.get("age")
    let local = sessionStorage.getItem("age")
    console.log("Cookie", cookie)
    if (cookie || local) setAge(false)
    loadVideo()
    console.log(
      data.Slider.edges.sort((a, b) => a.node.position - b.node.position)
    )
    setListSlider(
      data.Slider.edges.sort((a, b) => a.node.position - b.node.position)
    )
    // setMatches(data['LaLiga'].edges);
  }, [])

  const loadVideo = () => {
    const link = data.video.code
    setLinkVideo(
      `https://www.youtube.com/embed/${link}?autoplay=1&loop=1&controls=0&showinfo=0&fs=0&iv_load_policy=3&modestbranding=0&rel=0`
    )
    window.dataLayer.push({
      event: "GAEvent",
      event_category: "Video",
      event_action: "Play",
      event_label: "Futbol",
      interaction: true,
    })
  }

  const loadMatches = nameLeague => {
    setMatches(data[nameLeague].edges)
    handlerButtonLeagues(nameLeague)
  }

  const handlerButtonLeagues = league => {
    if (league === "LaLiga") {
      setButtonLaLiga(true)
      setButtonPremierLeague(false)
      setIconLaLiga(LaLigaBlack)
      setIconPremierLeague(PremierWhite)
    } else {
      setButtonLaLiga(false)
      setButtonPremierLeague(true)
      setIconLaLiga(LaLigaWhite)
      setIconPremierLeague(PremierBlack)
    }
  }

  const parallaxTitle = [
    {
      start: "self",
      duration: 500,
      properties: [
        {
          freeze: false,
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
        {
          startValue: 150,
          endValue: 0,
          property: "translateX",
        },
      ],
    },
  ]

  const parallaxButton = [
    {
      start: "self",
      duration: 300,
      properties: [
        {
          startValue: 0,
          endValue: 1,
          property: "opacity",
        },
        {
          startValue: 100,
          endValue: 0,
          property: "translateX",
        },
      ],
    },
  ]

  return !age ? (
    <div id="budX">
      <SEO title={"Fútbol"} />
      <Layout className={styles.Layout}>
        <Container fluid className={styles.BudX}>
          {/* video */}
          <div className={styles.videoWrapper}>
            <iframe
              src={linkVideo}
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            />
          </div>
          {/* arrowDown */}
          <Row className="d-flex justify-content-center m-0">
            <Col xs={"auto"}>
              <a
                onClick={e =>
                  document
                    .querySelector("#budxSlider")
                    .scrollIntoView({ behavior: "smooth" })
                }
              >
                <img src={ArrowDown} className="ArrowDown" />
              </a>
            </Col>
          </Row>

          <div style={{ marginTop: "10px" }} id="budxSlider">
            <Slider sliders={listSlider} />
          </div>

          {/* Partidos */}
          {/* <Row className={styles.RowInfo} id="budxInfo">
                  <Col xs={9} className={styles.ColInfo}>
                      
                      
                      <Plx parallaxData={ parallaxTitle } >
                          <h1>CALENDARIO DE PARTIDOS</h1>
                      </Plx>
                      
                     
                      <Plx parallaxData={ parallaxButton } >
                        <Tab.Container id="list-group-tabs-example">
                          <ListGroup horizontal className="justify-content-center">
                            <ListGroup.Item active={buttonLaLiga} onClick={e => loadMatches('LaLiga')} className={styles.LaLiga}>
                              <img src={iconLaLiga}/>
                            </ListGroup.Item>
                            <ListGroup.Item active={buttonPremierLeague} onClick={e => loadMatches('Premier')} className={styles.Premier}>
                              <img src={iconPremierLeague}/>
                            </ListGroup.Item>
                          </ListGroup>
                        </Tab.Container>
                      </Plx>
                      
                      
                      <Row className={styles.ListMatches}>
                        <ListMatches matches={matches}/>
                      </Row>
                  </Col>
                </Row> */}
        </Container>
      </Layout>
    </div>
  ) : (
    <AgeGate path="/futbol" />
  )
}

export default BudX

export const data = graphql`
  query BudX {
    Slider: allFutbolSlider {
      edges {
        node {
          title
          position
          textButton
          linkButton
          paragraph
          imageDesktop
          imageMobile
        }
      }
    }
    video: futbolVideo {
      code
    }
    LaLiga: allLaLiga {
      edges {
        node {
          teamHome
          teamAway
          goalHome
          goalAway
          date
        }
      }
    }
    Premier: allPremierLeague {
      edges {
        node {
          teamHome
          teamAway
          goalHome
          goalAway
          date
        }
      }
    }
  }
`
